<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <!-- <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px" @change="Search" clearable> </el-date-picker>
              </el-form-item>
              <el-form-item label="部门">
                <el-cascader :options="departmentData" :props="{ expandTrigger: 'hover', checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }" :clearable="true" style="width: 100%" filterable @change="handleOption" size="small"></el-cascader>
              </el-form-item>
              <el-form-item label="">
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="订单号/接诊医生/联系方式" @keyup.enter.native="Search"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div> -->
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 0px)" row-key="departmentId" :tree-props="{ children: 'childrens', hasChildren: 'childrens.length>0' }">
          <el-table-column prop="departmentName" label="业务组名称"></el-table-column>
          <el-table-column prop="usersPayMoney" label="直属组订单总额(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.usersPayMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="sumPayMoney" label="订单总额含下级(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.sumPayMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="departmentSumBindUsers" label="业务员数" align="center">
            <template slot-scope="scope">
              <el-link type="primary" @click="showInfoBtn(scope.row)" v-if="scope.row.departmentSumBindUsers.length>0">{{ scope.row.departmentSumBindUsers.length }}</el-link>
              <span v-else>{{ scope.row.departmentSumBindUsers.length }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="usersBindNum" label="绑定医生" align="center"></el-table-column>
          <el-table-column prop="sumBindNum" label="绑定医生含下级" align="center"></el-table-column>
          <el-table-column prop="usersMZNum" label="订单数" align="center"></el-table-column>
          <el-table-column prop="sumMZNum" label="订单数含下级" align="center"></el-table-column>
          <el-table-column label="操作" align="center" width="150px">
            <template slot-scope="scope">
              <span class="primary optionBtn" :class="scope.row.sumMZNum==0?'primaryDis':''" @click="prviewBtn(scope.row)">明细</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog :visible.sync="bindUsersDialog" width="55%">
      <el-table :data="infoData" stripe style="width: 100%" height="440px">
        <el-table-column type="index" label="编号" width="50"></el-table-column>
        <el-table-column prop="businessName" label="业务员" />
        <el-table-column prop="departmentName" label="所属业务组" align="center" />
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { Outpatient } from '@/components/DrugDomain/Outpatient.js'
import { Department } from '@/components/HospitalDomain/Department.js'
export default {
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    var department = new Department(this.TokenClient, this.Services.Authorization)
    return {
      OutpatientDomain: outpatient,
      Department: department,
      tableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      departmentData: [],
      departmentUsersData: [],
      searchForm: {
        keyWord: '',
        datepicker: '',
        departmentId: 0,
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      infoData: [],
      bindUsersDialog: false,
      pageIndex2: 1,
      dataTotal2: 1,
      pageSize2: 0,
    }
  },
  mounted() {
    this.getDepartmentUsers()
  },
  methods: {
    showInfoBtn(item) {
      this.infoData=item.departmentSumBindUsers
      this.bindUsersDialog=true
    },
    prviewBtn(item) {
      sessionStorage.setItem('bindUsers', JSON.stringify(item.departmentSumBindUsers))
      this.$router.push({
        path: './BindUsersMZStats',
      })
    },
    getDepartment() {
      let organizationId = JSON.parse(sessionStorage.getItem('userInfo')).organizationId
      var _this = this
      _this.Department.GetDepartment(
        organizationId,
        function (data) {
          _this.departmentData = data.data
        },
        function (err) {
          _this.departmentData = []
        }
      )
    },
    getDepartmentUsers() {
      var _this = this
      _this.Department.GetDepartmentUsersTree(
        true,
        function (data) {
          _this.departmentUsersData = data.data
          _this.getList()
        },
        function (err) {
          _this.departmentUsersData = []
        }
      )
    },
    changePage(pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    getList() {
      var _this = this
      let organizationId = JSON.parse(sessionStorage.getItem('userInfo')).organizationId
      _this.tableData = []
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      console.log(item.keyWord)
      _this.OutpatientDomain.BusinessDepartmentMZInputInfo(
        _this.departmentUsersData,
        organizationId,
        item.startTime,
        item.endTime,
        function (data) {
          _this.tableData = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    Search(event) {
      this.getList()
    },
  },
}
</script>

<style scoped>
.dialog-form {
  width: 85%;
}
</style>
